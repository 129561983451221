import { connect } from 'react-redux';

import { logoutUser as logoutUserAction } from '@actions/auth/logoutUser';
import { setPage } from '@actions/page/page';

import InfoSection from './info-section';

function mapDispatchToProps(dispatch) {
    return {
        setPage: page => dispatch(setPage(page)),
        logoutUser: () => dispatch(logoutUserAction()),
    };
}

export default connect(
    state => ({
        currentPage: state.currentPage,
        currentUser: state.currentUser,
    }),
    mapDispatchToProps,
)(InfoSection);
