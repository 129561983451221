import React from 'react';

import { Image } from '@components';

import './for-user-section.scss';

const ForUserSection = () => {
    return <section className='for-user'>
        <div className='for-user__inner'>
            <div className='container'>
                <div className='for-user__title-wrap'>
                    <h2 className='title for-user__title'>Для пользователей</h2>
                </div>
                <div className='uk-flex uk-child-width-1-3 for-user__list'>
                    <a href='#' className='for-user__item'>
                        <Image src='main/for-new-users/new-user.svg' />
                        <h4 className='for-user__item-title'>Новым пользователям</h4>
                        <div className='for-user__item-play'></div>
                    </a>
                    <a href='#' className='for-user__item'>
                        <Image src='main/for-new-users/terms.svg' />
                        <h4 className='for-user__item-title'>Правила пользования сервисом</h4>
                        <div className='for-user__item-play'></div>
                    </a>
                    <a href='#' className='for-user__item'>
                        <Image src='main/for-new-users/faq.svg' />
                        <h4 className='for-user__item-title'>Ответы на частые вопросы</h4>
                        <div className='for-user__item-play'></div>
                    </a>
                </div>
                <div className='uk-flex uk-child-width-1-3 for-user__list'>
                    <a href='#' className='for-user__item'>
                        <Image src='main/for-new-users/courts-base.svg' />
                        <h4 className='for-user__item-title'>База судов</h4>
                        <div className='for-user__item-play'></div>
                    </a>
                    <a href='#' className='for-user__item'>
                        <Image src='main/for-new-users/search-specialist.svg' />
                        <h4 className='for-user__item-title'>Как правильно найти специалиста?</h4>
                        <div className='for-user__item-play'></div>
                    </a>
                </div>
            </div>
        </div>
    </section>;
};

export default ForUserSection;
