import React, { useState } from 'react';

import { Button, CustomSelect, Image } from '@components';
import { useAdaptive } from '@hooks';

const options = [
    { value: 'Популярные направления 1', label: 'Популярные направления 1' },
    { value: 'Популярные направления 2', label: 'Популярные направления 2' },
];

import './directions-section.scss';

const DirectionsSection = () => {
    const { isMobile } = useAdaptive();

    const [selectValue, setSelectValue] = useState(options[0]);

    return <section className='directions'>
        <div className='directions__inner'>
            <div className='container'>
                {isMobile ? <div className='directions__title-wrap'>
                    <div className='directions__title-inner'>
                        <h2 className='title directions__title'>Направления</h2>
                    </div>
                    <div>
                        <CustomSelect className='dropdown-2 without-border' value={selectValue} options={options} onChange={setSelectValue} />
                        <Button view='primary-outline' size='mini' href='#' className='directions__title-btn'>
                            Все направления
                        </Button>
                    </div>
                </div> : <div className='directions__title-wrap'>
                    <div className='directions__title-inner'>
                        <h2 className='title directions__title'>Направления</h2>
                        <CustomSelect className='dropdown-2 without-border' value={selectValue} options={options} onChange={setSelectValue} />
                    </div>
                    <Button view='primary-outline' size='mini' href='#' className='directions__title-btn'>
                        Все направления
                    </Button>
                </div>}
                <div className='directions__list'>
                    <a href='' className='directions__item'>
                        <div className='directions__item-img'>
                            <Image src='bankruptcy.svg' />
                        </div>
                        <h4 className='directions__item-title'>Банкротство</h4>
                    </a>
                    <a href='' className='directions__item'>
                        <div className='directions__item-img'>
                            <Image src='health.svg' />
                        </div>
                        <h4 className='directions__item-title'>Фармацевтика и здравоохранение</h4>
                    </a>
                    <a href='' className='directions__item'>
                        <div className='directions__item-img'>
                            <Image src='police.svg' />
                        </div>
                        <h4 className='directions__item-title'>Уголовное право и процесс</h4>
                    </a>
                    <a href='' className='directions__item'>
                        <div className='directions__item-img'>
                            <Image src='brain.svg' />
                        </div>
                        <h4 className='directions__item-title'>Интеллектуальная собственность</h4>
                    </a>
                    <a href='' className='directions__item'>
                        <div className='directions__item-img'>
                            <Image src='flat.svg' />
                        </div>
                        <h4 className='directions__item-title'>Семейное/Наследственное право</h4>
                    </a>
                    <a href='' className='directions__item'>
                        <div className='directions__item-img'>
                            <Image src='labour.svg' />
                        </div>
                        <h4 className='directions__item-title'>Трудовое и миграционное право</h4>
                    </a>
                    <a href='' className='directions__item'>
                        <div className='directions__item-img'>
                            <Image src='insurance.svg' />
                        </div>
                        <h4 className='directions__item-title'>Страховое право</h4>
                    </a>
                    <a href='' className='directions__item'>
                        <div className='directions__item-img'>
                            <Image src='nature.svg' />
                        </div>
                        <h4 className='directions__item-title'>Природные ресурсы</h4>
                    </a>
                    <a href='' className='directions__item'>
                        <div className='directions__item-img'>
                            <Image src='antimonopoly.svg' />
                        </div>
                        <h4 className='directions__item-title'>Антимонопольное</h4>
                    </a>
                </div>
            </div>
        </div>
    </section>;
};

export default DirectionsSection;
