import React, { Component } from 'react';

import { SiteLayout } from '@layouts';

import DirectionsSection from './directions-section';
import ForUserSection from './for-user-section';
import InfoSection from './info-section';
import NewsSection from './news-section';
import SpecialistsSection from './specialists-section';

import './main-page.scss';

class MainPage extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className='main-page'>
                <InfoSection />
                <ForUserSection />
                <DirectionsSection />
                <NewsSection />
                <SpecialistsSection />
            </div>
        );
    }
}

MainPage.layout = {
    component: SiteLayout,
};

export default MainPage;
