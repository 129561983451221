import Link from 'next/link';
import React, { useEffect, useMemo } from 'react';
import Slider from 'react-slick';

import { Button } from '@components';

import SearchForm from './search-form-jsx';

const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => <span {...props}>{children}</span>;

import './info-section.scss';

const InfoSection = props => {
    const { setPage } = props;

    useEffect(() => {
        setPage('/');
    }, [setPage]);

    const infoSlider = useMemo(() => ({
        dots: true,
        dotsClass: 'slick-dots info__slider-dotnav',
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <SlickButtonFix>
            <button className='info__slider-prev__btn'>
                <img src='/assets/images/arrow-left-white.svg' />
            </button>
        </SlickButtonFix>,
        nextArrow: <SlickButtonFix>
            <button className='info__slider-next__btn'>
                <img src='/assets/images/arrow-right-white.svg' />
            </button>
        </SlickButtonFix>,
    }), []);

    return <section className='info'>
        <div className='info__inner'>
            <div className='container'>
                <div className='info__wrap'>
                    <div className='info__search'>
                        <SearchForm />
                    </div>
                    <div className='info__info'>
                        {props.currentUser.authUserToken ?
                            <div>
                                <span className='info__info-greeting'>Добрый вечер</span>
                                <h2 className='info__info-name'>
                                    <span>{props.currentUser.userLastName}</span>
                                    {props.currentUser.userName} {props.currentUser.userMiddleName}
                                </h2>
                                <div className='info__info-btn__group'>
                                    <Link href='/site/personal-page/workspace' as='/personal/workspace'>
                                        <a className='info__info-btn__personal'>
                                            <Button view='secondary-outline-dark'>
                                                Личный кабинет
                                            </Button>
                                        </a>
                                    </Link>
                                    <Button view='secondary-outline-dark' className='info__info-btn__exit' onClick={props.logoutUser}>
                                        Выход
                                    </Button>
                                </div>
                            </div>
                            :
                            <div>
                                <h3 className='info__info-title'>Вход в Юридический сервис</h3>
                                <p className='info__info-descr'>
                                    Войдите или зарегистрируйтесь, чтобы получить полный доступ к услугам Юридического сервиса
                                </p>
                                <Link href='/site/login-page' as='/login'>
                                    <Button className='info__sign-in__btn'>
                                        Войти в личный кабинет
                                    </Button>
                                </Link>
                                <Link href='/site/register-page' as='/register'>
                                    <div className='info__sign-up'>
                                        <Button view='secondary-outline-dark' className='info__sign-up__btn'>
                                            Зарегистрироваться
                                        </Button>
                                    </div>
                                </Link>
                            </div>
                        }

                        <div className='info__slider-wrap'>
                            <Slider {...infoSlider}>
                                <div className='info__slider-item'>
                                    <h3 className='info__slider-item__title'>Заголовок направления</h3>
                                    <p className='info__slider-item__descr'>
                                        О практике урегулирования налоговых споров расскажет на вебинаре представитель ФНС России
                                    </p>
                                    <a href='' className='info__slider-play'></a>
                                </div>
                                <div className='info__slider-item'>
                                    <h3 className='info__slider-item__title'>Заголовок направления</h3>
                                    <p className='info__slider-item__descr'>
                                        О практике урегулирования налоговых споров расскажет на вебинаре представитель ФНС России
                                    </p>
                                    <a href='' className='info__slider-play'></a>
                                </div>
                                <div className='info__slider-item'>
                                    <h3 className='info__slider-item__title'>Заголовок направления</h3>
                                    <p className='info__slider-item__descr'>
                                        О практике урегулирования налоговых споров расскажет на вебинаре представитель ФНС России
                                    </p>
                                    <a href='' className='info__slider-play'></a>
                                </div>
                                <div className='info__slider-item'>
                                    <h3 className='info__slider-item__title'>Заголовок направления</h3>
                                    <p className='info__slider-item__descr'>
                                        О практике урегулирования налоговых споров расскажет на вебинаре представитель ФНС России
                                    </p>
                                    <a href='' className='info__slider-play'></a>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>;
};

export default InfoSection;
