import createDecorator from 'final-form-focus';
import { useRouter } from 'next/router';
import qs from 'qs';
import React, { useCallback, useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';

import { Button, unexpectedExceptionNotification } from '@components';
import { AutosuggestInput, SelectInput } from '@form-components';
import GpsIcon from '@images/gps-icon.svg';
import axios from '@utils/axios';
import { required } from '@validators';

const focusOnErrors = createDecorator();

const SearchForm = () => {
    const [searchForm, setSearchForm] = useState(null);

    useEffect(() => {
        const fetchFormData = async () => {
            try {
                const { data: { form } } = await axios.get('/api/site/page/main/form/search/data');

                form.fields.categories_specialist.choice = form.fields.categories_specialist.choice.map(x => ({ ...x, value: x.key }));
                form.fields.law_education.choice = form.fields.law_education.choice.map(x => ({ ...x, value: x.key }));
                form.fields.region.choice = form.fields.region.choice.map(x => ({ ...x, value: x.key }));

                setSearchForm(form);
            } catch (ex) {
                if (ex?.response?.status) {
                    unexpectedExceptionNotification();
                }
            }
        };

        fetchFormData();
    }, []);

    const router = useRouter();
    const onSubmit = useCallback(values => {
        if (searchForm) {
            const params = {
                flt_law_education: values.law_education.value,
                flt_region: values.region ? searchForm.fields.region.choice.find(x => x.label === values.region)?.value : null,
            };

            router.push(`/specialists/${values.categories_specialist.value}?${qs.stringify(params)}`);
        }
    }, [router, searchForm]);

    if (!searchForm) {
        return null;
    }

    const { categories_specialist, law_education, region } = searchForm.fields;

    return (
        <>
            <h2 className='title info__title'>{searchForm.title}</h2>
            <Form
                onSubmit={onSubmit}
                decorators={[focusOnErrors]}
                render={({ handleSubmit }) => {
                    return <form onSubmit={handleSubmit} noValidate={true}>
                        <div className='info__search-selects'>
                            <div className='info__search-selects__wrap'>
                                <Field
                                    name='categories_specialist'
                                    label={categories_specialist.label}
                                    component={SelectInput}
                                    validate={required()}
                                    choice={categories_specialist.choice}
                                    defaultValue={categories_specialist.choice[0]}
                                    secondary={true}
                                />
                            </div>
                            <div className='info__search-selects__wrap'>
                                <Field
                                    name='law_education'
                                    label={law_education.label}
                                    component={SelectInput}
                                    validate={required()}
                                    choice={law_education.choice}
                                    defaultValue={law_education.choice[0]}
                                    secondary={true}
                                />
                            </div>
                        </div>
                        <div className='info__search-reg'>
                            <Field
                                name='region'
                                label={region.label}
                                component={AutosuggestInput}
                                choice={region.choice}
                                placeholder='— введите субъект РФ —'
                                rightIcon={<GpsIcon />}
                                secondary={true}
                            />
                        </div>
                        <Button type='submit' className='info__search-btn'>
                            {searchForm.label_button_search}
                        </Button>
                    </form>;
                }}
            />
        </>);
};

export default SearchForm;
