import Link from 'next/link';
import React, { useMemo } from 'react';

import { Button, Tag } from '@components';
import { useAdaptive } from '@hooks';

import './news-section.scss';

const NewsSection = () => {
    const { isTablet } = useAdaptive();

    const mainNewsImage = useMemo(() => <img className='news__main-img' src='https://i.ibb.co/CMkC01q/news-main-img.jpg' alt='main-news-img' />, []);

    const mainNewsTitleAndTags = useMemo(() => <div className='news__main-title__wrap news__title-wrap'>
        <h3 className='news__title'>
            <Link href='/'>Интересные законопроекты марта</Link>
        </h3>
        <div className='news__title-inner'>
            <div className='news__tags-list'>
                <time className='news__date' dateTime='02-01'>
                    1 февраля
                </time>
                <Tag>#Законодательство</Tag>
                <Tag>#Практика</Tag>
            </div>
        </div>
    </div>, []);

    const mainNewsDescription = useMemo(() => <p className='news__content'>
        С 1 июля 2019 года девелоперы потеряют доступный и дешевый источник финансирования – средства дольщиков. Теперь они
        станут неприкосновенные на эскроу-счетах до окончания строительства.
    </p>, []);

    return <section className='news'>
        <div className='news__inner'>
            <div className='container'>
                <div className='news__wrap'>
                    <div className='news__main'>
                        {isTablet ? <>
                            {mainNewsImage}
                            <div>
                                {mainNewsTitleAndTags}
                                {mainNewsDescription}
                            </div>
                        </> : <>
                            {mainNewsImage}
                            {mainNewsTitleAndTags}
                            {mainNewsDescription}
                        </>}
                    </div>

                    <div className='news__other news__other--1'>
                        <div className='news__other-title__wrap news__title-wrap'>
                            <h3 className='news__title'>
                                <Link href='/'>Самые интересные дела за март</Link>
                            </h3>
                            <div className='news__title-inner'>
                                <div className='news__tags-list'>
                                    <time className='news__date' dateTime='01-29'>
                                        29 января
                                    </time>
                                    <Tag>#Практика</Tag>
                                    <Tag>#Образование</Tag>
                                    <Tag>#Законодательство</Tag>
                                </div>
                            </div>
                        </div>
                        <p className='news__content'>
                            Окружной суд разрешил дело арендатора, которому затопило помещение, и спор крупной компании с ФНС о том, облагается ли
                            налогом на прибыль зачет векселей.
                        </p>
                    </div>
                    <div className='news__other news__other--2'>
                        <div className='news__other-title__wrap news__title-wrap'>
                            <h3 className='news__title'>
                                <Link href='/'>Как претензия влияет на срок исковой давности</Link>
                            </h3>
                            <div className='news__title-inner'>
                                <div className='news__tags-list'>
                                    <time className='news__date' dateTime='02-05'>
                                        5 февраля
                                    </time>
                                    <Tag>#Образование</Tag>
                                    <Tag>#Законодательство</Tag>
                                </div>
                            </div>
                        </div>
                        <p className='news__content'>
                            В Обзор практики Верховного суда попало дело из экономколлегии. Истец пытался доказать, что не пропустил срок исковой
                            давности на взыскание неустойки.
                        </p>
                    </div>
                    <div className='news__other news__other--3'>
                        <div className='news__other-title__wrap news__title-wrap'>
                            <h3 className='news__title'>
                                <Link href='/'>Самые интересные законопроекты марта</Link>
                            </h3>
                            <div className='news__title-inner'>
                                <div className='news__tags-list'>
                                    <time className='news__date' dateTime='02-01'>
                                        1 февраля
                                    </time>
                                    <Tag>#Практика</Tag>
                                    <Tag>#Законодательство</Tag>
                                </div>
                            </div>
                        </div>
                        <p className='news__content'>
                            С 1 июля 2019 года девелоперы потеряют доступный и дешевый источник финансирования – средства дольщиков. Теперь они
                            станут неприкосновенные на эскроу-счетах до окончания строительства.
                        </p>
                    </div>
                    <Button view='primary-outline' size='medium' href='#' className='news__btn'>
                        Все новости
                    </Button>
                </div>
            </div>
        </div>
    </section>;
};

export default NewsSection;
