import Link from 'next/link';
import React, { useMemo, useState } from 'react';
import Slider from 'react-slick';

import { Button, CustomSelect, SlickButtonFix, Tag } from '@components';

const options = [
    { value: 'Уголовные дела', label: 'Уголовные дела' },
    { value: 'Административные дела', label: 'Административные дела' },
];

import { useAdaptive } from '@hooks';

import './specialists-section.scss';

const SpecialistsSection = () => {
    const { isMobile } = useAdaptive();

    const specialistsSlider = useMemo(() => ({
        infinite: true,
        variableWidth: true,
        dots: true,
        slidesToScroll: 1,
        dotsClass: 'slick-dots specialists__slider-dotnav',
        prevArrow: <SlickButtonFix>
            <span className='specialists__slider-btn--prev'></span>
        </SlickButtonFix>,
        nextArrow: <SlickButtonFix>
            <span className='specialists__slider-btn--next'></span>
        </SlickButtonFix>
        ,
    }), []);

    const [selectValue, setSelectValue] = useState(options[0]);

    const allRecommendationsButton = useMemo(() => <Button view='primary-outline' size='mini' href='#' className='specialists__title-btn'>
        Все рекомендации
    </Button>, []);

    return <section className='specialists'>
        <div className='container'>
            <div className='specialists__inner'>
                <div className='specialists__title-wrap'>
                    <div className='specialists__title-inner'>
                        <h2 className='title specialists__title'>Рекомендуем</h2>
                    </div>
                    <div className='specialists__title-inner specialists__title-inner--big'>
                        <CustomSelect className='dropdown single-select without-border' value={selectValue} options={options} onChange={setSelectValue} />
                        {!isMobile && allRecommendationsButton}
                    </div>
                </div>
                <Slider {...specialistsSlider}>
                    <div className='specialists__slider-item'>
                        <div className='specialists__photo'>
                            <img src='https://i.ibb.co/NjPK0hc/specialist.jpg' alt='specialist' />
                        </div>
                        <h4 className='specialists__name'>
                            <Link href='/'>
                                <>
                                    <span>Варшавин</span>
                                    Сергей Петрович
                                </>
                            </Link>
                        </h4>
                        <p className='specialists__location'>Москва, Россия</p>
                        <div className='specialists__tags'>
                            <Tag primary={true}>Уголовные дела</Tag>
                        </div>
                        <div className='specialists__rating-wrap'>
                            <div className='specialists__rating-inner'>
                                <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                        d='M11.1093 4.74536C11.4804 4.01811 12.5196 4.01811 12.8907 4.74536L14.4667 7.83349C14.612 8.11821 14.8847 8.31631 15.2003 8.36652L18.6243 8.91107C19.4307 9.03932 19.7518 10.0276 19.1748 10.6053L16.7248 13.0584C16.499 13.2846 16.3948 13.6051 16.4446 13.9209L16.9848 17.3456C17.112 18.1521 16.2713 18.7629 15.5436 18.3927L12.4534 16.8207C12.1685 16.6757 11.8315 16.6757 11.5466 16.8207L8.45643 18.3927C7.72871 18.7629 6.88802 18.1521 7.01523 17.3456L7.55539 13.9209C7.60519 13.6051 7.50105 13.2846 7.27516 13.0584L4.82518 10.6053C4.24821 10.0276 4.56933 9.03932 5.37567 8.91107L8.79966 8.36652C9.11535 8.31631 9.38801 8.11821 9.53331 7.83349L11.1093 4.74536Z'
                                        fill='#F7F8FA'
                                    />
                                    <path
                                        fillRule='evenodd'
                                        clipRule='evenodd'
                                        d='M9 8.31293V18.1162L8.45646 18.3927C7.72874 18.7629 6.88805 18.1521 7.01526 17.3456L7.55542 13.9209C7.60522 13.6052 7.50108 13.2846 7.27519 13.0585L4.82521 10.6053C4.24824 10.0276 4.56936 9.03934 5.3757 8.9111L8.79969 8.36654C8.86882 8.35555 8.93588 8.33746 9 8.31293Z'
                                        fill='#F8E5B8'
                                    />
                                    <path
                                        fillRule='evenodd'
                                        clipRule='evenodd'
                                        d='M12 4.19995V16.712C11.8445 16.712 11.6891 16.7482 11.5466 16.8207L8.45646 18.3927C7.72874 18.7629 6.88805 18.1521 7.01526 17.3456L7.55542 13.9209C7.60522 13.6052 7.50108 13.2846 7.27519 13.0585L4.82521 10.6053C4.24824 10.0276 4.56936 9.03934 5.3757 8.9111L8.79969 8.36654C9.11538 8.31634 9.38804 8.11824 9.53334 7.83352L11.1093 4.74539C11.2949 4.38177 11.6474 4.19996 12 4.19995Z'
                                        fill='#F8E5B8'
                                    />
                                </svg>
                                <span className='specialists__rating'>2.9</span>
                            </div>
                            <a href='#' className='specialists__reviews'>
                                28 отзывов
                            </a>
                        </div>
                    </div>
                    <div className='specialists__slider-item'>
                        <div className='specialists__photo'>
                            <img src='https://i.ibb.co/0mDv048/expert-2.jpg' alt='specialist' />
                        </div>
                        <h4 className='specialists__name'>
                            <Link href='/'>
                                <>
                                    <span>Варшавин</span>
                                    Сергей Петрович
                                </>
                            </Link>
                        </h4>
                        <p className='specialists__location'>Москва, Россия</p>
                        <div className='specialists__tags'>
                            <Tag primary={true}>Уголовные дела</Tag>
                        </div>
                        <div className='specialists__rating-wrap'>
                            <div className='specialists__rating-inner'>
                                <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                        d='M11.1093 4.74536C11.4804 4.01811 12.5196 4.01811 12.8907 4.74536L14.4667 7.83349C14.612 8.11821 14.8847 8.31631 15.2003 8.36652L18.6243 8.91107C19.4307 9.03932 19.7518 10.0276 19.1748 10.6053L16.7248 13.0584C16.499 13.2846 16.3948 13.6051 16.4446 13.9209L16.9848 17.3456C17.112 18.1521 16.2713 18.7629 15.5436 18.3927L12.4534 16.8207C12.1685 16.6757 11.8315 16.6757 11.5466 16.8207L8.45643 18.3927C7.72871 18.7629 6.88802 18.1521 7.01523 17.3456L7.55539 13.9209C7.60519 13.6051 7.50105 13.2846 7.27516 13.0584L4.82518 10.6053C4.24821 10.0276 4.56933 9.03932 5.37567 8.91107L8.79966 8.36652C9.11535 8.31631 9.38801 8.11821 9.53331 7.83349L11.1093 4.74536Z'
                                        fill='#F7F8FA'
                                    />
                                    <path
                                        fillRule='evenodd'
                                        clipRule='evenodd'
                                        d='M9 8.31293V18.1162L8.45646 18.3927C7.72874 18.7629 6.88805 18.1521 7.01526 17.3456L7.55542 13.9209C7.60522 13.6052 7.50108 13.2846 7.27519 13.0585L4.82521 10.6053C4.24824 10.0276 4.56936 9.03934 5.3757 8.9111L8.79969 8.36654C8.86882 8.35555 8.93588 8.33746 9 8.31293Z'
                                        fill='#F8E5B8'
                                    />
                                    <path
                                        fillRule='evenodd'
                                        clipRule='evenodd'
                                        d='M12 4.19995V16.712C11.8445 16.712 11.6891 16.7482 11.5466 16.8207L8.45646 18.3927C7.72874 18.7629 6.88805 18.1521 7.01526 17.3456L7.55542 13.9209C7.60522 13.6052 7.50108 13.2846 7.27519 13.0585L4.82521 10.6053C4.24824 10.0276 4.56936 9.03934 5.3757 8.9111L8.79969 8.36654C9.11538 8.31634 9.38804 8.11824 9.53334 7.83352L11.1093 4.74539C11.2949 4.38177 11.6474 4.19996 12 4.19995Z'
                                        fill='#F8E5B8'
                                    />
                                </svg>
                                <span className='specialists__rating'>2.9</span>
                            </div>
                            <a href='#' className='specialists__reviews'>
                                28 отзывов
                            </a>
                        </div>
                    </div>
                    <div className='specialists__slider-item'>
                        <div className='specialists__photo'>
                            <img src='https://i.ibb.co/TrZ4Dqc/expert-3.jpg' alt='specialist' />
                        </div>
                        <h4 className='specialists__name'>
                            <Link href='/'>
                                <>
                                    <span>Варшавин</span>
                                    Сергей Петрович
                                </>
                            </Link>
                        </h4>
                        <p className='specialists__location'>Москва, Россия</p>
                        <div className='specialists__tags'>
                            <Tag primary={true}>Уголовные дела</Tag>
                        </div>
                        <div className='specialists__rating-wrap'>
                            <div className='specialists__rating-inner'>
                                <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                        d='M11.1093 4.74536C11.4804 4.01811 12.5196 4.01811 12.8907 4.74536L14.4667 7.83349C14.612 8.11821 14.8847 8.31631 15.2003 8.36652L18.6243 8.91107C19.4307 9.03932 19.7518 10.0276 19.1748 10.6053L16.7248 13.0584C16.499 13.2846 16.3948 13.6051 16.4446 13.9209L16.9848 17.3456C17.112 18.1521 16.2713 18.7629 15.5436 18.3927L12.4534 16.8207C12.1685 16.6757 11.8315 16.6757 11.5466 16.8207L8.45643 18.3927C7.72871 18.7629 6.88802 18.1521 7.01523 17.3456L7.55539 13.9209C7.60519 13.6051 7.50105 13.2846 7.27516 13.0584L4.82518 10.6053C4.24821 10.0276 4.56933 9.03932 5.37567 8.91107L8.79966 8.36652C9.11535 8.31631 9.38801 8.11821 9.53331 7.83349L11.1093 4.74536Z'
                                        fill='#F7F8FA'
                                    />
                                    <path
                                        fillRule='evenodd'
                                        clipRule='evenodd'
                                        d='M9 8.31293V18.1162L8.45646 18.3927C7.72874 18.7629 6.88805 18.1521 7.01526 17.3456L7.55542 13.9209C7.60522 13.6052 7.50108 13.2846 7.27519 13.0585L4.82521 10.6053C4.24824 10.0276 4.56936 9.03934 5.3757 8.9111L8.79969 8.36654C8.86882 8.35555 8.93588 8.33746 9 8.31293Z'
                                        fill='#F8E5B8'
                                    />
                                    <path
                                        fillRule='evenodd'
                                        clipRule='evenodd'
                                        d='M12 4.19995V16.712C11.8445 16.712 11.6891 16.7482 11.5466 16.8207L8.45646 18.3927C7.72874 18.7629 6.88805 18.1521 7.01526 17.3456L7.55542 13.9209C7.60522 13.6052 7.50108 13.2846 7.27519 13.0585L4.82521 10.6053C4.24824 10.0276 4.56936 9.03934 5.3757 8.9111L8.79969 8.36654C9.11538 8.31634 9.38804 8.11824 9.53334 7.83352L11.1093 4.74539C11.2949 4.38177 11.6474 4.19996 12 4.19995Z'
                                        fill='#F8E5B8'
                                    />
                                </svg>
                                <span className='specialists__rating'>2.9</span>
                            </div>
                            <a href='#' className='specialists__reviews'>
                                28 отзывов
                            </a>
                        </div>
                    </div>
                    <div className='specialists__slider-item'>
                        <div className='specialists__photo'>
                            <img src='https://i.ibb.co/njSCK0h/expert-4.jpg' alt='specialist' />
                        </div>
                        <h4 className='specialists__name'>
                            <Link href='/'>
                                <>
                                    <span>Варшавин</span>
                                    Сергей Петрович
                                </>
                            </Link>
                        </h4>
                        <p className='specialists__location'>Москва, Россия</p>
                        <div className='specialists__tags'>
                            <Tag primary={true}>Уголовные дела</Tag>
                        </div>
                        <div className='specialists__rating-wrap'>
                            <div className='specialists__rating-inner'>
                                <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                        d='M11.1093 4.74536C11.4804 4.01811 12.5196 4.01811 12.8907 4.74536L14.4667 7.83349C14.612 8.11821 14.8847 8.31631 15.2003 8.36652L18.6243 8.91107C19.4307 9.03932 19.7518 10.0276 19.1748 10.6053L16.7248 13.0584C16.499 13.2846 16.3948 13.6051 16.4446 13.9209L16.9848 17.3456C17.112 18.1521 16.2713 18.7629 15.5436 18.3927L12.4534 16.8207C12.1685 16.6757 11.8315 16.6757 11.5466 16.8207L8.45643 18.3927C7.72871 18.7629 6.88802 18.1521 7.01523 17.3456L7.55539 13.9209C7.60519 13.6051 7.50105 13.2846 7.27516 13.0584L4.82518 10.6053C4.24821 10.0276 4.56933 9.03932 5.37567 8.91107L8.79966 8.36652C9.11535 8.31631 9.38801 8.11821 9.53331 7.83349L11.1093 4.74536Z'
                                        fill='#F7F8FA'
                                    />
                                    <path
                                        fillRule='evenodd'
                                        clipRule='evenodd'
                                        d='M9 8.31293V18.1162L8.45646 18.3927C7.72874 18.7629 6.88805 18.1521 7.01526 17.3456L7.55542 13.9209C7.60522 13.6052 7.50108 13.2846 7.27519 13.0585L4.82521 10.6053C4.24824 10.0276 4.56936 9.03934 5.3757 8.9111L8.79969 8.36654C8.86882 8.35555 8.93588 8.33746 9 8.31293Z'
                                        fill='#F8E5B8'
                                    />
                                    <path
                                        fillRule='evenodd'
                                        clipRule='evenodd'
                                        d='M12 4.19995V16.712C11.8445 16.712 11.6891 16.7482 11.5466 16.8207L8.45646 18.3927C7.72874 18.7629 6.88805 18.1521 7.01526 17.3456L7.55542 13.9209C7.60522 13.6052 7.50108 13.2846 7.27519 13.0585L4.82521 10.6053C4.24824 10.0276 4.56936 9.03934 5.3757 8.9111L8.79969 8.36654C9.11538 8.31634 9.38804 8.11824 9.53334 7.83352L11.1093 4.74539C11.2949 4.38177 11.6474 4.19996 12 4.19995Z'
                                        fill='#F8E5B8'
                                    />
                                </svg>
                                <span className='specialists__rating'>2.9</span>
                            </div>
                            <a href='#' className='specialists__reviews'>
                                28 отзывов
                            </a>
                        </div>
                    </div>
                    <div className='specialists__slider-item'>
                        <div className='specialists__photo'>
                            <img src='https://i.ibb.co/m81Dfh7/expert-5.jpg' alt='specialist' />
                        </div>
                        <h4 className='specialists__name'>
                            <Link href='/'>
                                <>
                                    <span>Варшавин</span>
                                    Сергей Петрович
                                </>
                            </Link>
                        </h4>
                        <p className='specialists__location'>Москва, Россия</p>
                        <div className='specialists__tags'>
                            <Tag primary={true}>Уголовные дела</Tag>
                        </div>
                        <div className='specialists__rating-wrap'>
                            <div className='specialists__rating-inner'>
                                <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                        d='M11.1093 4.74536C11.4804 4.01811 12.5196 4.01811 12.8907 4.74536L14.4667 7.83349C14.612 8.11821 14.8847 8.31631 15.2003 8.36652L18.6243 8.91107C19.4307 9.03932 19.7518 10.0276 19.1748 10.6053L16.7248 13.0584C16.499 13.2846 16.3948 13.6051 16.4446 13.9209L16.9848 17.3456C17.112 18.1521 16.2713 18.7629 15.5436 18.3927L12.4534 16.8207C12.1685 16.6757 11.8315 16.6757 11.5466 16.8207L8.45643 18.3927C7.72871 18.7629 6.88802 18.1521 7.01523 17.3456L7.55539 13.9209C7.60519 13.6051 7.50105 13.2846 7.27516 13.0584L4.82518 10.6053C4.24821 10.0276 4.56933 9.03932 5.37567 8.91107L8.79966 8.36652C9.11535 8.31631 9.38801 8.11821 9.53331 7.83349L11.1093 4.74536Z'
                                        fill='#F7F8FA'
                                    />
                                </svg>
                                <span className='specialists__rating'>0</span>
                            </div>
                            <a href='#' className='specialists__reviews specialists__reviews--null'>
                                0 отзывов
                            </a>
                        </div>
                    </div>
                    <div className='specialists__slider-item'>
                        <div className='specialists__photo'>
                            <img src='https://i.ibb.co/NjPK0hc/specialist.jpg' alt='specialist' />
                        </div>
                        <h4 className='specialists__name'>
                            <Link href='/'>
                                <>
                                    <span>Варшавин</span>
                                    Сергей Петрович
                                </>
                            </Link>
                        </h4>
                        <p className='specialists__location'>Москва, Россия</p>
                        <div className='specialists__tags'>
                            <Tag primary={true}>Уголовные дела</Tag>
                        </div>
                        <div className='specialists__rating-wrap'>
                            <div className='specialists__rating-inner'>
                                <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                        d='M11.1093 4.74536C11.4804 4.01811 12.5196 4.01811 12.8907 4.74536L14.4667 7.83349C14.612 8.11821 14.8847 8.31631 15.2003 8.36652L18.6243 8.91107C19.4307 9.03932 19.7518 10.0276 19.1748 10.6053L16.7248 13.0584C16.499 13.2846 16.3948 13.6051 16.4446 13.9209L16.9848 17.3456C17.112 18.1521 16.2713 18.7629 15.5436 18.3927L12.4534 16.8207C12.1685 16.6757 11.8315 16.6757 11.5466 16.8207L8.45643 18.3927C7.72871 18.7629 6.88802 18.1521 7.01523 17.3456L7.55539 13.9209C7.60519 13.6051 7.50105 13.2846 7.27516 13.0584L4.82518 10.6053C4.24821 10.0276 4.56933 9.03932 5.37567 8.91107L8.79966 8.36652C9.11535 8.31631 9.38801 8.11821 9.53331 7.83349L11.1093 4.74536Z'
                                        fill='#F7F8FA'
                                    />
                                </svg>
                                <span className='specialists__rating'>0</span>
                            </div>
                            <a href='#' className='specialists__reviews specialists__reviews--null'>
                                0 отзывов
                            </a>
                        </div>
                    </div>
                </Slider>

                {isMobile && <div className='specialists__title-btn-wrap'>
                    {allRecommendationsButton}
                </div>}
            </div>
        </div>
    </section>;
};

export default SpecialistsSection;
